import React, { useState, useEffect } from 'react';

const PlayerManagement = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const contentContainerStyle = { display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', gap: '100px' };
    const contentContainerStyle2 = { display: 'flex', flexDirection: isMobile ? 'column' : 'row-reverse', alignItems: 'center', gap: '20px' };


    return (
        <div className="responsive-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px', marginTop: '15%' }} className="z-10 max-w-5xl w-full font-mono text-sm lg:flex">
                <div className="bg-black text-white py-12">
                    <h2 className="text-4xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">Examples</h2>
                </div>
                <div className="content-container" style={contentContainerStyle}>
                    <img src="https://cdn.economyplus.solutions/discord-intigration-realms-and-servers_2.png" alt="User being banned with Fairplay" style={{ borderRadius: '10px', maxWidth: '100%' }} />
                    <div>
                        {/* <h1 style={{ color: 'white', fontSize: '2rem' }} className="font-mono">Management</h1> */}
                        {/* <p style={{ color: 'lightgrey', marginTop: '20px', textAlign: 'center' }} className="font-mono">Manage actions to your realm right from your own discord server.</p> */}
                    </div>
                </div>
                <div className="content-container" style={contentContainerStyle2}>
                    <img src="https://cdn.economyplus.solutions/discord-intigration-realms-and-servers_3.png" alt="Realm map command" style={{ borderRadius: '10px', maxWidth: '100%' }} />
                    <div>
                        {/* <h1 style={{ color: 'white', fontSize: '2rem' }} className="font-mono">Utility tools</h1>
                        <p style={{ color: 'lightgrey', marginTop: '20px', textAlign: 'center' }} className="font-mono">View your realm map right from discord</p> */}
                    </div>
                </div>
                <div className="content-container" style={contentContainerStyle}>
                    <img src="https://cdn.economyplus.solutions/discord-intigration-realms-and-servers_4.png" alt="Realm connectivity" style={{ borderRadius: '10px', maxWidth: '100%' }} />
                    <div>
                        {/* <h1 style={{ color: 'white', fontSize: '2rem' }} className="font-mono">Connectivity</h1>
                        <p style={{ color: 'lightgrey', marginTop: '20px', textAlign: 'center' }} className="font-mono">Log ingame events, such as chat, joins, and more.</p> */}
                    </div>
                </div>
                <div className="content-container" style={contentContainerStyle2}>
                    <img src="https://cdn.economyplus.solutions/wg1wpumh.png" alt="analytics webpage" style={{ borderRadius: '10px', maxWidth: '70%' }} />
                    <div>
                        <h1 style={{ color: 'white', fontSize: '2rem' }} className="font-mono">Analytics</h1>
                        <p style={{ color: 'lightgrey', marginTop: '20px', textAlign: 'center' }} className="font-mono">View on demand realm metrics and track your realm activity in realm time.</p>
                    </div>
                </div>
                <div className="content-container" style={contentContainerStyle}>
                    <img src="https://cdn.economyplus.solutions/ecodemo.webp" alt="Ingame Addon" style={{ borderRadius: '10px', maxWidth: '100%' }} />
                    <div>
                        <h1 style={{ color: 'white', fontSize: '2rem' }} className="font-mono">Fairplay Marketplace</h1>
                        <p style={{ color: 'lightgrey', marginTop: '20px', textAlign: 'center' }} className="font-mono">Jumpstart your realm, have user welcomes, professional gui shops, moderation menu, and more right out of the box!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayerManagement;
